import React, {useEffect} from 'react'
import axios from "axios";
import {Oval} from 'react-loader-spinner'
import {useParams} from "react-router-dom";
import config from './config'

let client = axios.create({
    baseURL: config.API,
    responseType: 'json'
});

function Decorder() {
    let {shortcode} = useParams()
    useEffect(() => {
        let fetchUrl = async () => {
            await client.request({
                url: "tools/decode/shortcode/" + shortcode,
                method: "GET"
            }).then(result => {
                window.location.href = result.data.url;
            })
        }
        fetchUrl()
    }, [shortcode]);

    return <div className="App">
        <header className="App-header">
            <Oval
                color={"#0ead69"}
                height={100}
                width={100}/>
            <p>Redirecting...</p>
        </header>
    </div>

}

export default Decorder
