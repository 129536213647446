const daily = {
    API_URL: "https://devapi.crave.it/",
};
const production = {
    API_URL: "https://api-5cf8df86-3e7c-441a-942a-c26fd762d73c.crave.it/",
};
const stage = {
    API_URL: "https://stageapi.crave.it/",
};

let config;
switch (process.env.REACT_APP_ENV) {
    case "prod":
        config = production;
        break;
    case "stage":
        config = stage;
        break;
    default:
        config = daily;
}
export default {
    API: config.API_URL,
}




