import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Decoder from './Decoder'

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:shortcode" element={<Decoder/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
